import React from 'react';
import tw from 'twin.macro';
import KeyPoint from '../../components/key-point';
import ArticleLayout from '../../layouts/article';

const Center = tw.div`mt-4 md:mt-6 text-base md:text-lg text-center`;
const CTA = tw.a`mx-0.5 text-purple-600 font-semibold hover:underline`;

export default () => (
  <ArticleLayout category="about" title="加入我们，共同成长" subtitle="期待你的到来">
    <KeyPoint
      index="○"
      title="硬件开发工程师"
      content="熟练掌握 MCU 设计；独立完成智能硬件产品的项目设计与样品制作；关键元器件的技术选型；熟悉常用通信协议。"
    />
    <KeyPoint
      index="○"
      title="渠道拓展 / 项目经理"
      content="进行大客户开发及维护，建立长期友好的合作关系；良好的沟通技巧和组织能力；与项目方沟通并撰写方案。"
    />
    <Center>
      请发送简历到<CTA href="mailto:lhzbxx@gmail.com?subject=职位申请">邮箱</CTA>；
      如果需要我们主动联系您，请
      <CTA href="https://wj.qq.com/s2/9539174/a440/" target="_blank" rel="noreferrer">
        提交申请
      </CTA>
      给我们。
    </Center>
  </ArticleLayout>
);
